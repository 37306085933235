import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Container, Typography, Radio, FormControlLabel } from '@mui/material';
import useDebounce from '../hook/useDebounce';
import moment from 'moment';

interface Actor {
  nome: string;
  id: number;
}

interface Director {
  nome: string;
  id: number;
}

interface Movie {
  id: number;
  codigo: string;
  nome: string;
  codigoAntigo: string;
  status: string;
  saida: string;
  atores: Actor[];
  diretores: Director[];
}

interface Cliente {
  id: number;
  nome: string;
  codigo: string;
  cpf: string;
  residencia: string;
  bairro: string;
  cidade: string;
  uf: string;
  cep: string;
  restricoes: string;
  telefoner: string;
  telefonec: string;
}

interface ClientResponse {
  cliente: Cliente;
  filmesAlugados: Movie[];
}

const columns: GridColDef[] = [
  { field: 'id', headerName: 'Código', flex: 1 },
  { field: 'nome', headerName: 'Título', flex: 2 },
  { field: 'data', headerName: 'Data', flex: 1 },
  { field: 'codigo_antigo', headerName: 'Código Antigo', flex: 1 },
  { field: 'status', headerName: 'Status', flex: 1 },
];

const DataGridComponent: React.FC = () => {
  const [data, setData] = useState<GridRowsProp>([]);
  const [filteredData, setFilteredData] = useState<GridRowsProp>([]);
  const [pageSize, setPageSize] = useState<number>(5);
  const [searchBy, setSearchBy] = useState<'codigo' | 'nome'>('codigo');
  const [clientCode, setClientCode] = useState<string>('');
  const [clientName, setClientName] = useState<string>('');
  const [pesquisarNomeFilme, setPesquisarNomeFilme] = useState<string>('');
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const [selectedCliente, setSelectedCliente] = useState<Cliente | null>(null);

  const debouncedClientCode = useDebounce(clientCode, 500);
  const base_url = 'https://sandbox-sfvd50-be.leafdev.com.br';

  // Função para buscar clientes conforme o usuário digita
  const fetchClientes = async (value: string) => {
    if (value) {
      try {
        const response = await fetch(`${base_url}/api/clientes?nome=${value}`);
        const result = await response.json();
        setClientes(result); // Supondo que a resposta é uma lista de clientes
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    } else {
      setClientes([]);
    }
  };

  // Função para buscar dados do cliente e filmes alugados
  const fetchData = async (code: string) => {
    if (code) {
      try {
        const response = await fetch(`${base_url}/api/clientes/filme_cliente/${code}`);
        const result: ClientResponse = await response.json();

        if (!result || !result.filmesAlugados.length) {
          setClientName('');
          setData([]);
          setFilteredData([]);
        } else {
          const client = result.cliente;
          setClientName(client.nome);
          const filmes = result.filmesAlugados.map((movie, index) => ({
            id: `${movie.codigo}-${index}`,
            nome: movie.nome,
            data: movie.saida ? moment(movie.saida).format('DD/MM/YYYY') : '',
            codigo_antigo: movie.codigoAntigo,
            status: movie.status,
          }));
          setData(filmes);
          setFilteredData(filmes); // Define os dados filtrados iniciais
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setClientName('');
        setData([]);
        setFilteredData([]);
      }
    }
  };

  useEffect(() => {
    if (searchBy === 'codigo') {
      fetchData(debouncedClientCode);
    }
  }, [debouncedClientCode, searchBy]);

  // Função para lidar com a seleção do cliente
  const handleClienteSelect = (cliente: Cliente | null) => {
    setSelectedCliente(cliente);
    if (cliente) {
      setClientCode(cliente.codigo); // Define o código do cliente
      fetchData(cliente.codigo); // Chama a função para buscar filmes
    } else {
      setClientCode('');
    }
  };

  // Filtragem dos dados do filme conforme o usuário digita
  const handleFilmeSearch = (value: string) => {
    setPesquisarNomeFilme(value);
    const filtered = data.filter((row) =>
      row.nome.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered); // Atualiza os dados filtrados
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {clientName ? `Filmes alugados por ${clientName}` : 'Pesquise um cliente'}
      </Typography>

      <FormControlLabel
        control={
          <Radio
            checked={searchBy === 'nome'}
            onChange={() => setSearchBy('nome')}
          />
        }
        label="Pesquisar por Nome do Cliente"
      />
      <FormControlLabel
        control={
          <Radio
            checked={searchBy === 'codigo'}
            onChange={() => setSearchBy('codigo')}
          />
        }
        label="Pesquisar por Código do Cliente"
      />

      {searchBy === 'nome' && (
        <Autocomplete
          options={clientes}
          getOptionLabel={(option) => option.nome}
          onChange={(event, value) => handleClienteSelect(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Nome do Cliente"
              variant="outlined"
              onChange={(e) => fetchClientes(e.target.value)} // Chama a função de busca ao digitar
            />
          )}
        />
      )}

      {searchBy === 'codigo' && (
        <TextField
          label="Código do Cliente"
          variant="outlined"
          onChange={(e) => {
            const value = e.target.value;
            setClientCode(value);
            setClientes([]); // Limpa o autocomplete ao digitar o código
            fetchData(value); // Chama a função para buscar filmes diretamente ao digitar o código
          }}
          value={clientCode}
          fullWidth
          margin="normal"
        />
      )}

      <TextField
        label="Nome do Filme"
        variant="outlined"
        onChange={(e) => handleFilmeSearch(e.target.value)} // Atualiza a função de pesquisa ao digitar
        value={pesquisarNomeFilme}
        fullWidth
        margin="normal"
      />
      <div style={{ height: 680, width: '100%' }}>
        <DataGrid
          rows={filteredData} // Usa os dados filtrados
          columns={columns}
          pagination
         
        />
      </div>
    </Container>
  );
};

export default DataGridComponent;
